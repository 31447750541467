<template>
  <div class="container mt-5">
    <center>
      <h1>Accept or Reject</h1>
      <p class="w-50">
        Accept or Reject is a quiz show in which the player or chat selects a numbered briefcase
        from a group of 25, each of which contains a different prize. The player then selects additional briefcases to open,
        revealing the prize they contain. After each round, the game offers
        the player a certain prize to stop playing and go home. The player must
        then decide whether to accept or reject and continue playing, hoping for a better
        offer. The ultimate goal is to select the briefcase containing the highest prize
        and walk away with as much money as possible.
      </p>
    </center>
  </div>
  <button @click="logout" class="btn btn-danger logoutBTN">Logout</button>
</template>

<script>
import firebase from "firebase";

export default {
  methods: {
    openBorwsersource() {
      window.open("/browsersource/" + firebase.auth().currentUser.uid, "_blank").focus();
    },
    logout() {
      this.$auth0.logout({ logoutParams: { returnTo: "https://portal.lets.stream/" } });
    },
  },
};
</script>

<style scoped>
button {
  width: 10rem;
}
.logoutBTN {
  position: absolute;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
</style>
