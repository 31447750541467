<script>
import DynamicColorPicker from "./DynamicColorPicker.vue";

export default {
    data() {
        return {
            id: crypto.randomUUID(),
            localData: this.modelValue,
        }
    },
    props: ['modelValue', 'settings'],
    emits: ['update:modelValue'],
    components: {
        DynamicColorPicker,
    },
    watch: {
        localData() {
            this.$emit('update:modelValue', this.localData);
        }
    },
};

</script>

<template>
    <div>
        <label :for="this.id" class="form-label">{{this.settings.label}}</label>
        <div v-if="this.settings.type == 'string'">
            <input :id="this.id" class="form-control" type="text" v-model="localData">
        </div>
        <div v-else-if="this.settings.type == 'number'">
            <input :id="this.id" class="form-control" type="number" v-model="localData" :step="this.settings.step">
        </div>
        <div v-else-if="this.settings.type == 'boolean'">
            <div class="form-switch">
                <input :id="this.id" class="form-check-input" type="checkbox" v-model="localData">  
            </div>
        </div>
        <div v-else-if="this.settings.type == 'color'">
            <DynamicColorPicker v-model="localData" />
        </div>
    </div>
</template>
    
<style scoped>

</style>