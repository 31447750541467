<template>
  <div
    :style="{
      '--fontColor': settings.fontColor,
      '--stageColor1': settings.stageColor1,
      '--stageColor2': settings.stageColor2,
      '--stageColor3': settings.stageColor3,
      '--stageColorInactive1': settings.stageColorInactive1,
      '--stageColorInactive2': settings.stageColorInactive2,
      '--stageColorInactive3': settings.stageColorInactive3,
    }"
    class="voting"
  >
    <h1 v-if="game.headline" class="case-wrapper-heading">
      {{ game.headline }}
    </h1>
    <h1 v-else class="case-wrapper-heading">{{ game.bankOffer }}</h1>
    <canvas id="myChart" width="400" height="400"></canvas>
    <center>
      <div class="timerWrapper">
        <h1 class="timer mb-5">Timer: {{ timer }}</h1>
        <p class="timer-text mb-5">Type in chat what you want to vote for!</p>
      </div>
    </center>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import tmi from "tmi.js";

export default {
  props: ["game", "settings", "uid"],
  data() {
    return {
      timer: this.game.timer,
      labels: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
      ],
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      chart: null,
      votetAlready: [],
    };
  },
  mounted() {
    if (!this.game.voting) {
      return;
    }
      this.labels = [];
      this.data = [];

      if (this.game.votingYesNo) {
        this.labels = ["YES", "NO"];
        this.data = [0, 0];
      } else {
        for (let i = 0; i < this.game.cases.length; i++) {
          if (this.game.cases[i].active && (!this.game.cases[i].locked || this.game.stage >= 30)) {
            this.labels.push(i + 1);
            this.data.push(0);
          }
        }
      }

      const ctx = document.getElementById("myChart");

      var chart = new Chart(ctx, {
        type: "bar",

        data: {
          labels: this.labels,

          datasets: [
            {
              label: "Votes",
              data: this.data,
              backgroundColor: ["lightgray"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              beginAtZero: true,
              display: true,
              ticks: {
                color: "white",
                beginAtZero: true,
                stepSize: 1,
                font: { size: 30 },
              },
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });

      const client = new tmi.Client({
        channels: [this.game.username],
      });
      this.votetAlready = [];
      client.connect();

      // eslint-disable-next-line no-unused-vars
      client.on("message", (channel, tags, message, self) => {
        
        if (this.votetAlready.includes(tags["display-name"])) {
          return;
        }

        if (this.game.votingYesNo) {
          if (message.toLowerCase() == "yes") {
            this.votetAlready.push(tags["display-name"]);
            this.data[0] = this.data[0] + 1;
            chart.update();
          } else if (message.toLowerCase() == "no") {
            this.votetAlready.push(tags["display-name"]);
            this.data[1] = this.data[1] + 1;
            chart.update();
          }
        } else {
          if (message > 0 && message < 25) {
            let index = this.labels.findIndex((element) => {
              return element == message;
            });
            if (
              index >= 0 &&
              this.game.cases[message - 1] &&
              this.game.cases[message - 1].active &&
              (!this.game.cases[message - 1].locked || this.game.stage >= 30)
            ) {
              this.votetAlready.push(tags["display-name"]);
              this.data[index] = this.data[index] + 1;
              chart.update();
            }
          }
        }
      });

      this.timer = this.game.timer;

      let intervall = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(intervall);
          client.disconnect();
          this.timer = 0;
        } else {
          this.timer = this.timer - 1;
        }
      }, 1000);
  },
};
</script>

<style scoped>
.case-wrapper-heading {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translate(-50%);
  font-weight: bold;
  background: -webkit-linear-gradient(var(--stageColor1), var(--stageColor2));
  font-size: 4.5rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.voting {
  position: absolute;
  z-index: 1;
  width: 1920px;
  height: 1080px;
  background: rgb(0, 0, 0, 0.85);
}
#myChart {
  margin-top: 8rem;
  max-width: 97%;
  margin-left: 2%;
  max-height: 70%;
}
.timerWrapper {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translate(-50%);
}
.timer {
  font-weight: bold;
  background: -webkit-linear-gradient(var(--stageColor1), var(--stageColor2));
  margin: 0 !important;
  padding: 0 !important;
  font-size: 4.5rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.timer-text {
  margin: 0 !important;
  padding: 0 !important;
  color: white;
  font-size: 1.4rem;
}
</style>
