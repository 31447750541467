<template>
  <div v-if="isDemo" class="demoalert alert alert-warning w-50" role="alert">
    <b>You are in the demo version. Some functions are limited here.</b>
  </div>
  <div class="loadingwrapper" v-if="!isDemo && this.$auth0.isLoading.value">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-else-if="this.$auth0.isAuthenticated.value || noAuthRoute() || isDemo">
    <Navbar v-if="!this.$route.path.includes('/browsersource')" />
    <router-view v-if="firebaseAuthInit || noAuthRoute()" />
    <div v-else class="loadingwrapper">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  </div>
  <div v-else>
    <Redirect />
  </div>
</template>

<script>
import firebase from "firebase";
import axios from "axios";
import Redirect from "@/components/redirect";
import Navbar from "@/components/navbar.vue"

export default {
  data() {
    return {
      settings: {
        showBackground: true,
        fontColor: "black",
        stageColor1: "rgba(245, 227, 13, 1)",
        stageColor2: "rgba(209, 165, 42, 1)",
        stageColor3: "rgba(241, 231, 83, 1)",
        stageColorInactive1: "rgb(80, 79, 68)",
        stageColorInactive2: "rgb(107, 103, 88)",
        stageColorInactive3: "rgb(105, 104, 90)",
        caseImage: "",
        caseOpenImage: "",
        caseLockedImage: "",
        hideNumbers: false,
        caseNumberFontColor: "black",
      },
      gamefield: {
        fields: [
          {
            name: "Default List",
            fields: [
              {
                text: "1€",
                gewichtung: 1,
                active: true,
              },
              {
                text: "3€",
                gewichtung: 2,
                active: true,
              },
              {
                text: "5€",
                gewichtung: 3,
                active: true,
              },
              {
                text: "7€",
                gewichtung: 4,
                active: true,
              },
              {
                text: "10€",
                gewichtung: 5,
                active: true,
              },
              {
                text: "25€",
                gewichtung: 6,
                active: true,
              },
              {
                text: "50€",
                gewichtung: 7,
                active: true,
              },
              {
                text: "70€",
                gewichtung: 8,
                active: true,
              },
              {
                text: "100€",
                gewichtung: 9,
                active: true,
              },
              {
                text: "250€",
                gewichtung: 10,
                active: true,
              },
              {
                text: "500€",
                gewichtung: 11,
                active: true,
              },
              {
                text: "1.000€",
                gewichtung: 12,
                active: true,
              },
              {
                text: "2.500€",
                gewichtung: 13,
                active: true,
              },
              {
                text: "5.000€",
                gewichtung: 14,
                active: true,
              },
              {
                text: "10.000€",
                gewichtung: 15,
                active: true,
              },
              {
                text: "20.000€",
                gewichtung: 16,
                active: true,
              },
              {
                text: "30.000€",
                gewichtung: 17,
                active: true,
              },
              {
                text: "50.000€",
                gewichtung: 18,
                active: true,
              },
              {
                text: "70.000€",
                gewichtung: 19,
                active: true,
              },
              {
                text: "100.000€",
                gewichtung: 20,
                active: true,
              },
              {
                text: "250.000€",
                gewichtung: 21,
                active: true,
              },
              {
                text: "500.000€",
                gewichtung: 22,
                active: true,
              },
              {
                text: "750.000€",
                gewichtung: 23,
                active: true,
              },
              {
                text: "1.000.000€",
                gewichtung: 24,
                active: true,
              },
            ],
          },
        ],
        showCase: false,
        voting: false,
        headline: "",
        bankOffer: "",
        showBankOffer: false,
        selectedCaseIndex: 0,
        timer: 30,
        cases: [],
        stage: 0,
        username: "",
        votingYesNo: false,
        end: false,
        chatMode: true,
      },
      rewards: {
        lists: [
          {
            name: "Default List",
            fields: [
              {
                text: "1€",
                gewichtung: 1,
                active: true,
              },
              {
                text: "3€",
                gewichtung: 2,
                active: true,
              },
              {
                text: "5€",
                gewichtung: 3,
                active: true,
              },
              {
                text: "7€",
                gewichtung: 4,
                active: true,
              },
              {
                text: "10€",
                gewichtung: 5,
                active: true,
              },
              {
                text: "25€",
                gewichtung: 6,
                active: true,
              },
              {
                text: "50€",
                gewichtung: 7,
                active: true,
              },
              {
                text: "70€",
                gewichtung: 8,
                active: true,
              },
              {
                text: "100€",
                gewichtung: 9,
                active: true,
              },
              {
                text: "250€",
                gewichtung: 10,
                active: true,
              },
              {
                text: "500€",
                gewichtung: 11,
                active: true,
              },
              {
                text: "1.000€",
                gewichtung: 12,
                active: true,
              },
              {
                text: "2.500€",
                gewichtung: 13,
                active: true,
              },
              {
                text: "5.000€",
                gewichtung: 14,
                active: true,
              },
              {
                text: "10.000€",
                gewichtung: 15,
                active: true,
              },
              {
                text: "20.000€",
                gewichtung: 16,
                active: true,
              },
              {
                text: "30.000€",
                gewichtung: 17,
                active: true,
              },
              {
                text: "50.000€",
                gewichtung: 18,
                active: true,
              },
              {
                text: "70.000€",
                gewichtung: 19,
                active: true,
              },
              {
                text: "100.000€",
                gewichtung: 20,
                active: true,
              },
              {
                text: "250.000€",
                gewichtung: 21,
                active: true,
              },
              {
                text: "500.000€",
                gewichtung: 22,
                active: true,
              },
              {
                text: "750.000€",
                gewichtung: 23,
                active: true,
              },
              {
                text: "1.000.000€",
                gewichtung: 24,
                active: true,
              },
            ],
          },
        ],
        activeList: 0,
      },
      isDemo: window.location.href.includes('demo=true'),
      firebaseAuthInit: false
    };
  },
  async mounted() {
      let token = await this.$auth0.getAccessTokenSilently();
      let result = await axios.get(
        "https://webhook.streamgamestv.com/api/v1/auth/firebase",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
          params: {
            sku: "004",
          },
        }
      );
      let firebaseToken = result.data.firebaseToken;
      firebase
        .auth()
        .signInWithCustomToken(firebaseToken)
        .then(() => {
          this.createDB();
        })
        .catch((error) => {
          alert(error.message);
        });
    

    firebase.auth().onAuthStateChanged((user) => {
      if(user.uid) {
        this.firebaseAuthInit = true
      }
    })
  },
  methods: {
    createDB() {
      if (firebase.auth().currentUser) {
        var uid = firebase.auth().currentUser.uid;
        firebase
          .firestore()
          .collection(uid)
          .doc("rewards")
          .get()
          .then((doc) => {
            if (!doc.exists) {
              firebase.firestore().collection(uid).doc("settings").set(this.settings);

              firebase.firestore().collection(uid).doc("game").set(this.gamefield);

              firebase.firestore().collection(uid).doc("rewards").set(this.rewards);
            }
          });
      }
    },
    noAuthRoute() {
      if (window.location.pathname.includes("/browsersource")) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    Redirect,
    Navbar
  }
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
.clickable {
  cursor: pointer;
}
.alert {
  position: absolute;
  left: 50%;
  top: 0;
  height: 3.3rem;
  transform: translate(-50%);
}
.loadingwrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.demoalert {
  position: absolute !important;
  z-index: 5;
  top: 0.25rem;
  left: 50%;
  transform: translate(-50%);
}
i {
  color: white !important;
}
</style>
