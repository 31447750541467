<template>
  <div v-if="game" class="container mt-3">
    <center>
      <h1>Play</h1>
      Chat Mode
      <input
        @change="save"
        :disabled="game.stage != 0"
        v-model="game.chatMode"
        type="checkbox"
      />
      <div v-if="game.chatMode">
        <hr />
        <div class="mt-3">
          Timer:
          <input
            :disabled="game.stage != 0"
            style="width: 3rem"
            type="number"
            v-model="game.timer"
          />
          <br /><br />
          Twitch username:
          <input
            :disabled="game.stage != 0"
            style="width: 10rem"
            type="text"
            v-model="game.username"
          />
          <br />
          <button
            :disabled="game.stage != 0"
            @click="savePlaySettings"
            class="btn btn-light mt-3"
          >
            Save
          </button>
        </div>
        <hr />
        <button @click="startGame" v-if="game.stage == 0" class="btn btn-success mt-3">
          Start Game
        </button>
        <button
          @click="next"
          :disabled="onVoting || disableButton"
          v-else
          class="btn btn-success mt-3"
        >
          Start Voting
        </button>
        <button @click="showLockedCase" v-if="game.end" class="btn btn-dark mt-3">
          Show Locked Case
        </button>
      </div>
      <div v-else>
        <div v-if="game.end">
          <h1>Game Result:</h1>
          <h2>{{ game.bankOffer }}</h2>
          <button @click="showLockedCase" class="btn btn-dark mt-3">
          Show Locked Case
        </button>
        </div>
        <div v-else>
          <hr />
          <button
            v-if="game.stage == 0"
            @click="startGameInSingleMode"
            class="btn btn-success"
          >
            Start Game
          </button>
          
          <div class="mt-2">
            <h2>{{ game.headline }}</h2>
            <div v-if="game.stage != 0 && !game.votingYesNo" class="case-wrapper mt-5">
              <div class="row">
                <div
                  v-for="(Case, index) in game.cases"
                  :key="index"
                  :class="Case.active ? 'col' : 'hide'"
                >
                  <div
                    @click="selectCase(index)"
                    class="case"
                    :class="{ clickAble: !Case.locked }"
                  >
                    <div class="ImageTextContainer">
                      <h1 class="textCentered">{{ formatIndex(index + 1) }}</h1>
                      <img
                        v-if="Case.locked"
                        class="caseImg"
                        src="../assets/case_locked.png"
                        alt=""
                      />
                      <img v-else class="caseImg" src="../assets/case.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="game.votingYesNo" class="mt-3">
              <h2>Your offer: {{ game.bankOffer }}</h2>
              <br />
              <button @click="selectCase('YES')" class="btn btn-success mx-3">
                ACCEPT
              </button>
              <button @click="selectCase('NO')" class="btn btn-danger mx-3">
                REJECT
              </button>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div v-if="game.bankOffer && !game.end">
        Change bank offer:
        <input type="text" v-model="game.bankOffer" />
        <br>
        <button @click="showBankOffer" class="btn btn-success mx-2 mt-3">Show offer</button>
      </div>
    </center>
  </div>
  <center class="mt-5" v-else>
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </center>
  <button @click="resetGame" class="btn btn-danger resetGameBTN">Reset Game</button>
  <div class="selectLists">
    <label for="rewards">Choose active list: </label>

    <select v-if="rewards" v-model="rewards.activeList" name="rewards" class="mx-2">
      <option disabled value="">Please select one</option>
      <option v-for="(list, index) in rewards.lists" :key="index" :value="index">
        {{ list.name }}
      </option>
    </select>
  </div>
</template>

<script>
import firebase from "firebase";
import tmi from "tmi.js";

export default {
  data() {
    return {
      game: null,
      rewards: null,
      onVoting: false,
      disableButton: true,
      client: null,
      votetAlready: [],
      data: [],
      disableSelectCase: false,
    };
  },
  mounted() {
      firebase
        .firestore()
        .collection(firebase.auth().currentUser.uid)
        .doc("game")
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.game = doc.data();
          }
        });

        firebase
        .firestore()
        .collection(firebase.auth().currentUser.uid)
        .doc("rewards")
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.rewards = doc.data();
          }
        });
  },
  methods: {
    showLockedCase() {
      let index
      for(let i = 0; i < this.game.cases.length; i++) {
        if(this.game.cases[i].locked) {
          index = i
        }
      }
      this.game.showCase = true;
      this.game.selectedCaseIndex = index;
      this.game.showEndCase = true;
      this.save();
    },
    savePlaySettings() {
      if (!this.game.username) {
        alert("Please enter your twitch username");
        return;
      } else if (this.game.username.includes(" ")) {
        alert("Please enter your CORRECT twitch username, with no spaces in the name");
      }
      firebase
        .firestore()
        .collection(firebase.auth().currentUser.uid)
        .doc("game")
        .update({
          timer: this.game.timer,
          username: this.game.username,
        });
    },
    save() {
      firebase
        .firestore()
        .collection(firebase.auth().currentUser.uid)
        .doc("game")
        .update(this.game);
    },
    resetGame() {
      for (let i = 0; i < this.game.fields.length; i++) {
        this.game.fields[i].active = true;
      }
      this.game.cases = [];

      firebase
        .firestore()
        .collection(firebase.auth().currentUser.uid)
        .doc("game")
        .update({
          fields: this.game.fields,
          showCase: false,
          voting: false,
          headline: "",
          bankOffer: "",
          selectedCaseIndex: 0,
          cases: [],
          stage: 0,
          votingYesNo: false,
          end: false,
          showEndCase: false,
          showBankOffer: false
        });
      this.onVoting = false;
    },
    startGame() {
      if (!this.game.username) {
        alert("Please enter your twitch username");
        return;
      } else if (this.game.username.includes(" ")) {
        alert("Please enter your CORRECT twitch username, with no spaces in the name");
      }
      this.client = new tmi.Client({
        channels: [this.game.username],
      });
      this.client.connect();
      this.game.fields = this.rewards.lists[this.rewards.activeList].fields
      this.game.cases = this.createCases();
      this.game.stage = 1;
      this.setHeading();
      this.save();
      this.onVoting = false;
      this.disableButton = false;
    },
    startGameInSingleMode() {
      this.game.fields = this.rewards.lists[this.rewards.activeList].fields
      this.game.cases = this.createCases();
      this.game.stage = 1;
      this.setHeading();
      this.save();
    },
    formatIndex(index) {
      if (index < 10) {
        return "0" + index;
      }
      return index;
    },
    selectCase(caseIndex) {
      if ((this.game.cases[caseIndex] && this.game.cases[caseIndex].locked == true && this.game.stage != 30) || this.disableSelectCase
      ) {
        return;
      }
      this.disableSelectCase = true;

      if (this.game.stage == 1) {
        this.game.cases[caseIndex].locked = true;
        this.disableButton = false;
        this.disableSelectCase = false;
      } else if (this.game.stage >= 30) {
        this.game.end = true;
        this.game.bankOffer = this.game.cases[caseIndex].text;
        this.disableSelectCase = false;
      } else if (!this.game.votingYesNo) {
        setTimeout(() => {
        this.disableSelectCase = false;
      }, 5000);
        this.game.cases[caseIndex].active = false;
        this.game.selectedCaseIndex = caseIndex;
        this.game.showCase = true;
        let fieldIndex = this.game.fields.findIndex((element) => {
          return element.text == this.game.cases[this.game.selectedCaseIndex].text && element.active == true;
        });
        if (fieldIndex >= 0) {
          this.game.fields[fieldIndex].active = false;
        }
        setTimeout(() => {
          this.game.showCase = false;
          this.disableButton = false;
          this.setHeading();
          this.save();
        }, 5000);
      } else if (caseIndex == "YES") {
        this.game.end = true;
        this.disableSelectCase = false;
      } else if (caseIndex == "NO") {
        this.disableButton = false;
        this.disableSelectCase = false;
        this.game.bankOffer = "";
        this.game.showBankOffer = false
      }

      this.game.stage = this.game.stage + 1;
      this.checkForVotingYesNo();
      this.setHeading();
      this.save();
    },
    checkForVotingYesNo() {
      this.game.votingYesNo = false;
      if (
        this.game.stage == 9 ||
        this.game.stage == 16 ||
        this.game.stage == 21 ||
        this.game.stage == 25 ||
        this.game.stage == 27 ||
        this.game.stage == 29
      ) {
        this.game.votingYesNo = true;
      }
    },
    setHeading() {
      if (this.game.stage == 1) {
        this.game.headline = "CHOOSE A CASE TO LOCK";
      } else if (this.game.stage == 2) {
        this.game.headline = "SELECT 7 MORE CASES";
      } else if (this.game.stage == 3) {
        this.game.headline = "SELECT 6 MORE CASES";
      } else if (this.game.stage == 4) {
        this.game.headline = "SELECT 5 MORE CASES";
      } else if (this.game.stage == 5) {
        this.game.headline = "SELECT 4 MORE CASES";
      } else if (this.game.stage == 6) {
        this.game.headline = "SELECT 3 MORE CASES";
      } else if (this.game.stage == 7) {
        this.game.headline = "SELECT 2 MORE CASES";
      } else if (this.game.stage == 8) {
        this.game.headline = "SELECT 1 MORE CASE";
      } else if (this.game.stage == 9) {
        this.setBankOffer();
      } else if (this.game.stage == 10) {
        this.game.headline = "SELECT 6 CASES";
      } else if (this.game.stage == 11) {
        this.game.headline = "SELECT 5 MORE CASES";
      } else if (this.game.stage == 12) {
        this.game.headline = "SELECT 4 MORE CASES";
      } else if (this.game.stage == 13) {
        this.game.headline = "SELECT 3 MORE CASES";
      } else if (this.game.stage == 14) {
        this.game.headline = "SELECT 2 MORE CASES";
      } else if (this.game.stage == 15) {
        this.game.headline = "SELECT 1 MORE CASE";
      } else if (this.game.stage == 16) {
        this.setBankOffer();
      } else if (this.game.stage == 17) {
        this.game.headline = "SELECT 4 MORE CASES";
      } else if (this.game.stage == 18) {
        this.game.headline = "SELECT 3 MORE CASES";
      } else if (this.game.stage == 19) {
        this.game.headline = "SELECT 2 MORE CASES";
      } else if (this.game.stage == 20) {
        this.game.headline = "SELECT 1 MORE CASE";
      } else if (this.game.stage == 21) {
        this.setBankOffer();
      } else if (this.game.stage == 22) {
        this.game.headline = "SELECT 3 MORE CASES";
      } else if (this.game.stage == 23) {
        this.game.headline = "SELECT 2 MORE CASES";
      } else if (this.game.stage == 24) {
        this.game.headline = "SELECT 1 MORE CASE";
      } else if (this.game.stage == 25) {
        this.setBankOffer();
      } else if (this.game.stage == 26) {
        this.game.headline = "SELECT 1 MORE CASE";
      } else if (this.game.stage == 27) {
        this.setBankOffer();
      } else if (this.game.stage == 28) {
        this.game.headline = "SELECT 1 MORE CASE";
      } else if (this.game.stage == 29) {
        this.setBankOffer();
      } else if (this.game.stage == 30) {
        this.game.headline = "SELECT YOUR CASE";
      }
    },
    setBankOffer() {
      this.game.headline = "";
      let activeStages = [];
      for (let i = 0; i < this.game.fields.length; i++) {
        if (this.game.fields[i].active) {
          activeStages.push(this.game.fields[i]);
        }
      }
      let offerIndex = this.calculateOffer(activeStages);
      this.game.bankOffer = this.game.fields[offerIndex].text;
    },
    showBankOffer() {
      firebase
        .firestore()
        .collection(firebase.auth().currentUser.uid)
        .doc("game")
        .update({
          showBankOffer: true,
          bankOffer: this.game.bankOffer
        });
    },
    calculateOffer(remainingStages) {
      // Berechne den Durchschnittswert der verbleibenden Stufen
      let totalValue = 0;
      for (let i = 0; i < remainingStages.length; i++) {
        totalValue += remainingStages[i].gewichtung;
      }
      let averageValue = totalValue / remainingStages.length;

      // Runde den Durchschnittswert auf die nächste ganze Zahl
      let offer = Math.floor(averageValue);

      // Gib das Angebot zurück
      return offer - 1;
    },
    next() {
      this.onVoting = true;
      this.disableButton = true;
      this.game.showCase = false;
      this.game.voting = true;
      this.checkForVotingYesNo();
      this.save();

      let votingResult = "";
      this.votetAlready = [];

      if (this.game.votingYesNo) {
        this.data = [
          { text: "YES", value: 0 },
          { text: "NO", value: 0 },
        ];
      } else {
        this.data = [];
        for (let i = 0; i < this.game.cases.length; i++) {
          if (
            this.game.cases[i] &&
            this.game.cases[i].active &&
            (!this.game.cases[i].locked || this.game.stage >= 30)
          ) {
            this.data.push({ text: i + 1, value: 0 });
          }
        }
      }

      // eslint-disable-next-line no-unused-vars
      this.client.on("message", (channel, tags, message, self) => {
        if (this.votetAlready.includes(tags["display-name"]) || !this.onVoting) {
          return;
        }

        if (this.game.votingYesNo) {
          if (message.toLowerCase() == "yes") {
            this.votetAlready.push(tags["display-name"]);
            this.data[0].value = this.data[0].value + 1;
          } else if (message.toLowerCase() == "no") {
            this.votetAlready.push(tags["display-name"]);
            this.data[1].value = this.data[1].value + 1;
          }
        } else {
          if (message > 0 && message < 25) {
            let index = this.data.findIndex((element) => {
              return element.text == message;
            });
            if (index >= 0) {
              this.votetAlready.push(tags["display-name"]);
              this.data[index].value = this.data[index].value + 1;
            }
          }
        }
      });

      setTimeout(() => {
        this.onVoting = false;
        this.game.voting = false;

        votingResult = this.getRandomHighestValueObject(this.data);

        if (this.game.stage == 1) {
          this.game.cases[votingResult.text - 1].locked = true;
          this.disableButton = false;
        } else if (this.game.stage >= 30) {
          this.game.end = true;
          this.game.bankOffer = this.game.cases[votingResult.text - 1].text;
        } else if (!this.game.votingYesNo) {
          this.game.cases[votingResult.text - 1].active = false;
          this.game.selectedCaseIndex = votingResult.text - 1;
          this.game.showCase = true;
          let fieldIndex = this.game.fields.findIndex((element) => {
            return element.text == this.game.cases[this.game.selectedCaseIndex].text;
          });
          if (fieldIndex >= 0) {
            this.game.fields[fieldIndex].active = false;
          }
          setTimeout(() => {
            this.game.showCase = false;
            this.disableButton = false;
            this.setHeading();
            this.save();
          }, 5000);
        } else if (votingResult.text == "YES") {
          this.game.end = true;
        } else if (votingResult.text == "NO") {
          this.disableButton = false;
          this.game.bankOffer = "";
          this.game.showBankOffer = false
        }

        this.game.stage = this.game.stage + 1;
        this.setHeading();
        this.save();
      }, this.game.timer * 1300);
    },
    getRandomHighestValueObject(dataArray) {
      const highestValueObjects = dataArray.filter(
        (obj) => obj.value === Math.max(...dataArray.map((obj) => obj.value))
      );
      const randomIndex = Math.floor(Math.random() * highestValueObjects.length);
      const randomHighestValueObject = highestValueObjects[randomIndex];

      return randomHighestValueObject;
    },
    createCases() {
      let cases = JSON.parse(JSON.stringify(this.game.fields));
      this.shuffle(cases);

      for (let i = 0; i < cases.length; i++) {
        cases[i].active = true;
        cases[i].locked = false;
      }

      return cases;
    },
    shuffle(array) {
      array.sort(() => Math.random() - 0.5);
    },
  },
};
</script>

<style scoped>
.selectLists {
  position: absolute;
  top: 5rem;
  right: 1rem;
}
.resetGameBTN {
  position: absolute;
  top: 5rem;
  left: 6rem;
}
.case-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -43%);
  width: 66rem;
  color: var(--fontColor) !important;
}
.case-wrapper-heading {
  font-weight: bold;
  background: -webkit-linear-gradient(var(--stageColor1), var(--stageColor2));
  font-size: 4.5rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hide {
  display: none;
}
.case {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  max-width: 10rem;
}
.firstCaseDiv > h1 {
  font-size: 3.7rem;
  font-weight: bold;
  margin-top: 2rem;
  color: var(--caseNumberFontColor);
}
.firstCaseDiv {
 grid-row-start: 1;
 grid-column-start: 1;
}
.secondCaseDiv {
 grid-row-start: 1;
 grid-column-start: 1;
}
.clickAble {
  cursor: pointer;
}

.clickAble:hover {
  background: lightgray;
}
.caseImg {
  height: 8rem;
}
.ImageTextContainer {
  position: relative;
  text-align: center;
  color: white;
}
.textCentered {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-50%);
  font-size: 3.7rem;
  font-weight: bold;
  color: black;
}
</style>
