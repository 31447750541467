<template>
  <div class="container mt-5 pb-5">
    <center>
      <h1>Settings</h1>
    </center>
    <div v-if="settings" class="container-fluid mt-3" style="max-width: 85%">
      <h2>General</h2>
      <hr>
      <div class="col-md-6">
        <DynamicInput v-model="settings.showBackground" :settings="{label: 'Show background picture', type: 'boolean'}" />
      </div>
      <br>
      <div class="col-md-6">
        <DynamicInput v-model="settings.fontColor" :settings="{label: 'Font color', type: 'color'}" />
      </div>
      <br>
      <br>
      <h2>Stage</h2>
      <hr>
      <div class="col-md-6">
        <DynamicInput v-model="settings.stageColor1" :settings="{label: 'Stage color 1', type: 'color'}" />
      </div>
      <div class="col-md-6">
        <DynamicInput v-model="settings.stageColor2" :settings="{label: 'Stage color 2', type: 'color'}" />
      </div>
      <div class="col-md-6">
        <DynamicInput v-model="settings.stageColor3" :settings="{label: 'Stage color 3', type: 'color'}" />
      </div>
      <br>
      <br>
      <div class="col-md-6">
        <DynamicInput v-model="settings.stageColorInactive1" :settings="{label: 'Inactive Stage color 1', type: 'color'}" />
      </div>
      <div class="col-md-6">
        <DynamicInput v-model="settings.stageColorInactive2" :settings="{label: 'Inactive Stage color 2', type: 'color'}" />
      </div>
      <div class="col-md-6">
        <DynamicInput v-model="settings.stageColorInactive3" :settings="{label: 'Inactive Stage color 3', type: 'color'}" />
      </div>

      <div class="mt-5">
        <h2>Cases</h2>
        <hr>
        <div class="row">
          <div class="col-md-6">
            <DynamicInput v-model="settings.caseImage" :settings="{label: 'Normal Case Image', type: 'string'}" />
          </div>
          <div class="col-md-6">
            <DynamicInput v-model="settings.caseOpenImage" :settings="{label: 'Case Opened Image', type: 'string'}" />
          </div>
          <div class="col-md-6">
            <DynamicInput v-model="settings.caseLockedImage" :settings="{label: 'Locked Case Image', type: 'string'}" />
          </div>
          <div class="col-md-6">
            <DynamicInput v-model="settings.caseNumberFontColor" :settings="{label: 'Font Color', type: 'color'}" />
          </div>
          <div class="col-md-6">
            <DynamicInput v-model="settings.hideNumbers" :settings="{label: 'Hide numbers', type: 'boolean'}" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <toast ref="saveToast"
    title="Settings saved successfully"
    text="Your settings have been successfully saved and your overlay has been updated accordingly."
  />
  <button @click="save" class="btn btn-success btnSave">Save</button>
  <button @click="resetSettings" class="btn btn-danger resettBTN">Reset settings</button>
</template>

<script>
import "vue-color-kit/dist/vue-color-kit.css";
import firebase from "firebase";
import toast from "@/components/toast.vue";
import DynamicInput from "@/components/input/DynamicInput.vue";

export default {
  data() {
    return {
      settings: null,
      showColorPickerFontcolor: false,
      showColorPickerstageColor1: false,
      showColorPickerstageColor2: false,
      showColorPickerstageColor3: false,
      showColorPickerstageColorInactive1: false,
      showColorPickerstageColorInactive2: false,
      showColorPickerstageColorInactive3: false,
      settingsDefault: {
        showBackground: true,
        fontColor: "black",
        stageColor1: "rgba(245, 227, 13, 1)",
        stageColor2: "rgba(209, 165, 42, 1)",
        stageColor3: "rgba(241, 231, 83, 1)",
        stageColorInactive1: "rgb(80, 79, 68)",
        stageColorInactive2: "rgb(107, 103, 88)",
        stageColorInactive3: "rgb(105, 104, 90)",
        hideNumbers: false,
        caseNumberFontColor: "black"
      }
    };
  },
  mounted() {
      firebase
        .firestore()
        .collection(firebase.auth().currentUser.uid)
        .doc("settings")
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.settings = doc.data();
          }
        });
  },
  methods: {
    save() {
      if (this.settings) {
        firebase
          .firestore()
          .collection(firebase.auth().currentUser.uid)
          .doc("settings")
          .update(this.settings)
          .then(() => {
            this.$refs.saveToast.showToast();
          });
      }
    },
    resetSettings() {
        firebase
        .firestore()
        .collection(firebase.auth().currentUser.uid)
        .doc("settings")
        .update(this.settingsDefault);
    }
  },
  components: {
    DynamicInput,
    toast,
  },
};
</script>

<style>
.backBTN {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
.btnSave {
  position: absolute;
  top: 5rem;
  right: 1rem;
}
.resettBTN {
  position: absolute;
  top: 5rem;
  left: 6rem;
}
</style>
