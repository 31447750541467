<template>
  <div
    :style="{
      '--fontColor': settings.fontColor,
      '--stageColor1': settings.stageColor1,
      '--stageColor2': settings.stageColor2,
      '--stageColor3': settings.stageColor3,
      '--stageColorInactive1': settings.stageColorInactive1,
      '--stageColorInactive2': settings.stageColorInactive2,
      '--stageColorInactive3': settings.stageColorInactive3,
      '--caseNumberFontColor': settings.caseNumberFontColor,
    }"
  >
    <div v-if="game.stage != 0" class="container-table-left">
      <div
        v-for="(n, i) in 12"
        :key="n"
        class="stage"
        :class="!game.fields[i].active ? 'stageInactive' : ''"
      >
        <p class="stageNumber">{{ formatIndex(n) }}</p>
        <p class="stageText">{{ game.fields[i].text }}</p>
      </div>
    </div>
    <div v-if="game.stage != 0" class="container-table-right">
      <div
        v-for="(n, i) in 12"
        :key="n"
        class="stage"
        :class="!game.fields[i + 12].active ? 'stageInactive' : ''"
      >
        <p class="stageNumber">{{ formatIndex(n + 12) }}</p>
        <p class="stageText">{{ game.fields[i + 12].text }}</p>
      </div>
    </div>
    <Transition name="fade">
      <div v-if="game.end && !game.showEndCase" class="bankoffer-wrapper">
        <center>
          <h1 style="font-size: 5rem">Game Result:</h1>
          <h1>{{game.bankOffer}}</h1>
        </center>
      </div>
      <div v-else-if="game.showCase || game.showEndCase" class="show-case-wrapper">
        <div class="case-open"> 
          <div class="case-open-heading-wrapper">
            <h1>{{ game.cases[game.selectedCaseIndex].text }}</h1>
          </div>
          <img v-if="settings.caseOpenImage" class="caseopenImg" :src="settings.caseOpenImage" alt="" />
          <img v-else class="caseopenImg" src="../assets/case_open.png" alt="" />
        </div>
      </div>
      <div v-else-if="game.bankOffer" class="bankoffer-wrapper">
        <center v-if="game.showBankOffer">
          <h2>YOUR OFFER:</h2>
          <h1>{{game.bankOffer}}</h1>
        </center>
        <center v-else>
          <h2>WAITING FOR OFFER</h2>
        </center>
      </div>
      <div v-else class="case-wrapper">
        <center>
          <h1 v-if="game.stage == 0" class="case-wrapper-heading">Waiting for game to start...</h1>
          <h1 v-else class="case-wrapper-heading">{{ game.headline }}</h1>
        </center>
        <div class="row">
          <div
            v-for="(Case, index) in game.cases"
            :key="index"
            :class="Case.active ? 'col' : 'hide'"
          >
            <div class="case">
              
              <div class="secondCaseDiv" v-if="Case.locked">
                <img v-if="settings.caseLockedImage" class="caseImg" :src="settings.caseLockedImage" alt="" />
                <img v-else class="caseImg" src="../assets/case_locked.png" alt="" />
              </div>
              <div class="secondCaseDiv" v-else>
                <img v-if="settings.caseImage" class="caseImg" :src="settings.caseImage" alt="" />
                <img v-else class="caseImg" src="../assets/case.png" alt="" />
              </div>
              <div class="firstCaseDiv">
                <h1>{{ formatIndex(index + 1) }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: ["game", "settings"],
  methods: {
    formatIndex(index) {
      if (index < 10) {
        return "0" + index;
      }
      return index;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.hide {
  display: none;
}
.bankoffer-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  left: 50%;
  top: 15rem;
  transform: translate(-50%);
  width: 66rem;
  height: 100%;
}
.bankoffer-wrapper > center > h1 {
  background: -webkit-linear-gradient(var(--stageColor1), var(--stageColor2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 3.5rem;
  color: var(--caseNumberFontColor);
}
.bankoffer-wrapper > center > h2 {
  background: -webkit-linear-gradient(var(--stageColor1), var(--stageColor2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.show-case-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  left: 50%;
  top: 1rem;
  transform: translate(-50%);
  width: 66rem;
  height: 100%;
}
.case-open-heading-wrapper {
  position: absolute;
  text-align: center;
  left: 51%;
  top: 2rem;
  height: 16rem;
  transform: translate(-50%);
  width: 82%;
  color: white;
  font-weight: bold;
}
.case-open-heading-wrapper > h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  overflow-wrap: break-word;
  color: var(--caseNumberFontColor);
}
.case-open {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.caseopenImg {
  height: 35rem;
}

.case-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  left: 50%;
  top: 1rem;
  transform: translate(-50%);
  width: 66rem;
  color: var(--fontColor) !important;
}
.case-wrapper-heading {
  font-weight: bold;
  background: -webkit-linear-gradient(var(--stageColor1), var(--stageColor2));
  font-size: 4.5rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.case {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
}
.firstCaseDiv > h1 {
  font-size: 3.7rem;
  font-weight: bold;
  margin-top: 2rem;
  color: var(--caseNumberFontColor);
}
.firstCaseDiv {
 grid-row-start: 1;
 grid-column-start: 1;
}
.secondCaseDiv {
 grid-row-start: 1;
 grid-column-start: 1;
}

.caseImg {
  height: 8rem;
}

.container-table-left {
  position: absolute;
  top: 0rem;
  left: 1rem;
}
.container-table-right {
  position: absolute;
  top: 0rem;
  right: 1rem;
}
.stage > p {
  padding: 0;
  margin: 0;
}
.stageInactive {
  background: linear-gradient(
    197deg,
    var(--stageColorInactive1) 8%,
    var(--stageColorInactive2) 54%,
    var(--stageColorInactive3) 100%
  ) !important;
}
.stage {
  font-size: 2rem;
  margin-top: 1rem;
  padding-top: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  width: 25rem;
  padding: 0.6rem 1rem 0.6rem 1rem;
  background: linear-gradient(
    197deg,
    var(--stageColor1) 8%,
    var(--stageColor2) 54%,
    var(--stageColor3) 100%
  );
  display: flex;
  justify-content: space-between;
}

.stageNumber {
  padding-top: 0.25rem;
  margin-left: 1rem;
  color: var(--fontColor);
}
.stageText {
  padding-top: 0.25rem;
  margin-right: 1rem;
  margin-left: 1.5rem !important;
  color: var(--fontColor);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
