<template>
  <div v-if="settings && game">
    <Transition name="fade">
      <Voting v-if="game.voting" :game="game" :settings="settings" :uid="this.$route.params.uid" />
    </Transition>
    <Gamefield :game="game" :settings="settings" />
    <div v-if="settings.showBackground" class="background"></div>
  </div>
</template>

<script>
import Gamefield from "@/components/gamefield";
import Voting from "@/components/voting";
import firebase from "firebase";

export default {
  data() {
    return {
      game: null,
      settings: null,
      demoVersion: false
    };
  },
  mounted() {
    if(this.$route.params.uid == "gWTvVBEseqSCzgIP7yHRsdgHWjB2") {
      this.demoVersion = true
    }
      firebase
        .firestore()
        .collection(this.$route.params.uid)
        .doc("game")
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.game = doc.data();
          }
        });

      firebase
        .firestore()
        .collection(this.$route.params.uid)
        .doc("settings")
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.settings = doc.data();
            if(!this.settings.caseNumberFontColor) {
              this.settings.caseNumberFontColor = "black"
            }
          }
        });
  },
  methods: {
    
  },
  components: {
    Gamefield,
    Voting,
  },
};
</script>

<style scoped>
.background {
  position: absolute;
  width: 1920px;
  height: 1080px;
  background: url("https://t4.ftcdn.net/jpg/04/29/49/53/360_F_429495398_PaLj0wxT44HMNc6RFWMz0n8P8HdcqVP5.jpg")
    no-repeat center;
  background-size: cover;
  z-index: -1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.demoText {
  z-index: 500;
  position: absolute;
  bottom: 17rem;
  left: 50%;
  transform: translate(-50%);
  font-weight: bold;
  color: rgb(0, 255, 0);
}
</style>
