<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$auth0.loginWithRedirect();
  },
};
</script>

<style></style>
