<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Toast } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  data() {
    return {
      id: crypto.randomUUID(),
      generatedAt: Date.now(),
      timeScinceGenerated: 0,
    };
  },
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    showToast() {
      let myToast = document.getElementById(this.id);
      let bsToast = new Toast(myToast);
      let interval;

      this.generatedAt = Date.now();
      this.timeScinceGenerated = 0;
      bsToast.show();

      interval = setInterval(() => {
        this.timeScinceGenerated = Math.floor((Date.now() - this.generatedAt) / 1000);

        if(this.timeScinceGenerated > 5) {
          clearInterval(interval);
        }
      }, 1000);
    }
  }
};
</script>

<template>
  <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    <div class="toast" :id="id">
      <div class="toast-header">
          <strong class="me-auto">{{title}}</strong>
          <small>{{ timeScinceGenerated }} seconds ago</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast"></button>
      </div>
      <div class="toast-body">
          {{text}}
      </div>
    </div>
  </div>
</template>
    
<style scoped>

</style>