<template>
  <div class="sidebar">
    <div
      @click="this.$router.push('/')"
      class="sidebar-item"
      :class="active == '/' ? 'sidebar-active' : ''"
    >
      <i class="fa-solid fa-home"></i>
    </div>
    <div
      @click="this.$router.push('/play')"
      class="sidebar-item"
      :class="active == '/play' ? 'sidebar-active' : ''"
    >
      <i class="fa-solid fa-play"></i>
    </div>
    <div @click="openBrowsersource" class="sidebar-item">
      <i class="fa-regular fa-window-maximize"></i>
    </div>
    <div
      @click="this.$router.push('/rewards')"
      class="sidebar-item"
      :class="active == '/rewards' ? 'sidebar-active' : ''"
    >
      <i class="fa-solid fa-list"></i>
    </div>
    <div
      @click="this.$router.push('/settings')"
      class="sidebar-item"
      :class="active == '/settings' ? 'sidebar-active' : ''"
    >
      <i class="fa-solid fa-gear"></i>
    </div>

    <div @click="logout" class="sidebar-item-last">
      <i class="fa-solid fa-arrow-right-from-bracket"></i>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-fluid">

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#"></a>
          </li>
        </ul>
      </div>
      <div class="socials">
        <button @click="toggleDarkmode(null)" class="btn btn-light mx-5">
            {{ isDarkmode ? "🌑" : "☀" }}
          </button>
      </div>
    </div>
  </nav>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      active: "",
      isDarkmode: false,
    };
  },
  mounted() {
    this.$router.isReady().then(() => {
      this.active = this.$route.fullPath;
      this.$router.afterEach(() => {
        this.active = this.$route.fullPath;
      });
    });

    let darkmode = localStorage.getItem("darkmode")
    if(darkmode == "false" || darkmode == "true") {
      this.toggleDarkmode(localStorage.getItem("darkmode"))
    }
  },
  methods: {
    logout() {
      this.$auth0.logout({
        logoutParams: { returnTo: "https://portal.lets.stream/" },
      });
    },
    openBrowsersource() {
      window.open("browsersource/" + firebase.auth().currentUser.uid, "_blank").focus();
    },
    toggleDarkmode(darkmodeLocale) {
      if(darkmodeLocale == "true") {
        this.isDarkmode = true
      } else if(darkmodeLocale == "false") {
        this.isDarkmode = false
      } else {
        this.isDarkmode = !this.isDarkmode;
      }
      
      localStorage.setItem('darkmode', this.isDarkmode);

      const textElements = document.querySelectorAll(
        "p, h1, h2, h3, h4, h5, h6, div:not(:empty)"
      );

      if (this.isDarkmode) {
        document.body.style.backgroundColor = "#202124";
        textElements.forEach((paragraph) => {
          paragraph.style.color = "white";
        });
      } else {
        document.body.style.backgroundColor = "#fff";
        textElements.forEach((paragraph) => {
          paragraph.style.color = "black";
        });
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.socials {
  display: flex;
}
.socials > a {
  font-size: 1.3rem;
  padding-right: 0.7rem;
}
.navbar {
  background: #0e0e10 !important;
}
a {
  text-decoration: none;
  color: white;
}
.sidebar {
  position: fixed;
  padding-top: 4.2rem;
  top: 0;
  background: #0e0e10 !important;
  height: 100vh;
  width: 5rem;
  text-align: center;
}
.sidebar-active {
  background: #222227;
}
.sidebar-item {
  color: white;
  margin-left: -0.3rem;
  font-size: 1.3rem;
  cursor: pointer;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.sidebar-item-last {
  position: absolute;
  color: white;
  margin-left: 1.75rem;
  bottom: 1rem;
  font-size: 1.3rem;
  cursor: pointer;
  padding-bottom: 0.7rem;
}
</style>
