import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "firebase"
import { createAuth0 } from '@auth0/auth0-vue';

const firebaseConfig = {
    apiKey: "AIzaSyCcTR2hWCJX7ODR1X0DBMqxQLS1Jr6vsFw",
    authDomain: "acceptorrejectchat.firebaseapp.com",
    projectId: "acceptorrejectchat",
    storageBucket: "acceptorrejectchat.appspot.com",
    messagingSenderId: "496368766068",
    appId: "1:496368766068:web:b34f00caca90061c911521"
  };

firebase.initializeApp(firebaseConfig);


//auth0
const client = createAuth0({
  domain: "streamgamestv.eu.auth0.com",
  clientId: "Bba0tCN9hyHC0AcHIdP0qLNuT0EUA8WI",
  authorizationParams: {
      audience: "https://firebase.streamgamestv.com",
      redirect_uri: window.location.origin
  }
})

createApp(App).use(client).use(router).mount("#app");
