<script>
import { ColorPicker } from "vue-color-kit";

export default {
    data() {
        return {
            id: crypto.randomUUID(),
            isActive: false,
            localData: this.modelValue,
        };
    },
    props: ['modelValue'],
    emits: ['update:modelValue'],
    watch: {
        localData() {
            this.$emit('update:modelValue', this.localData);
        }
    },
    components: {
        ColorPicker,
    },
    methods: {
        changeColor(color) {
            const { r, g, b, a } = color.rgba;
            this.localData = `rgba(${r}, ${g}, ${b}, ${a})`;
        }
    }
};
</script>

<template>
    <div class="ColorPickerWrapper">
        <div :id="id" class="ColorPicker" @click="isActive = !isActive" :style="'background: ' + localData + ';'"></div>
        <div class="cover" v-if="isActive">
            <color-picker
                theme="light"
                :color="localData"
                @changeColor="changeColor"
            />
        </div>
    </div>
</template>

<style>
.hu-color-picker {
    padding: 10px;
    background: #1d2024;
    border-radius: 4px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .16);
    z-index: 1
}

.hu-color-picker.light {
    background: #f7f8f9
}

.hu-color-picker.light .color-show .sucker {
    background: #eceef0
}

.hu-color-picker.light .color-type .name {
    background: #e7e8e9
}

.hu-color-picker.light .color-type .value {
    color: #666;
    background: #eceef0
}

.hu-color-picker.light .colors.history {
    border-top: 1px solid #eee
}

.hu-color-picker canvas {
    vertical-align: top
}

.hu-color-picker .color-set {
    display: flex
}

.hu-color-picker .color-show {
    margin-top: 8px;
    display: flex
}

.saturation {
    position: relative;
    cursor: pointer
}

.saturation .slide {
    position: absolute;
    left: 100px;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, .3);
    pointer-events: none
}

.color-alpha {
    position: relative;
    margin-left: 8px;
    cursor: pointer
}

.color-alpha .slide {
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    height: 4px;
    background: #fff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .3);
    pointer-events: none
}

.sucker {
    width: 30px;
    fill: #9099a4;
    background: #2e333a;
    cursor: pointer;
    transition: all .3s
}

.sucker.active,
.sucker:hover {
    fill: #1593ff
}

.hue {
    position: relative;
    margin-left: 8px;
    cursor: pointer
}

.hue .slide {
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    height: 4px;
    background: #fff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .3);
    pointer-events: none
}

.colors {
    padding: 0;
    margin: 0
}

.colors.history {
    margin-top: 10px;
    border-top: 1px solid #2e333a
}

.colors .item {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 10px 0 0 10px;
    border-radius: 3px;
    box-sizing: border-box;
    vertical-align: top;
    display: inline-block;
    transition: all .1s;
    cursor: pointer
}

.colors .item:nth-child(8n+1) {
    margin-left: 0
}

.colors .item:hover {
    transform: scale(1.4)
}

.colors .item .alpha {
    height: 100%;
    border-radius: 4px
}

.colors .item .color {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px
}

.color-type {
    display: flex;
    margin-top: 8px;
    font-size: 12px
}

.color-type .name {
    width: 60px;
    height: 30px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    background: #252930
}

.color-type .value {
    flex: 1;
    height: 30px;
    min-width: 100px;
    padding: 0 12px;
    border: 0;
    color: #fff;
    background: #2e333a;
    box-sizing: border-box
}

.ColorPicker {
  cursor: pointer;
  width: 100%;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.cover {
    box-sizing: content-box;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cover * {
    box-sizing: inherit;
}
</style>
  