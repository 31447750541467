<template>
  <div class="container mt-5">
    <center>
      <h1>Edit Rewards</h1>

      <div v-if="rewards" class="row">
        <div class="col">
          <div class="mt-2" v-for="n in 12" :key="n">
            {{ getNumber(n) }}
            <input v-model="rewards.lists[rewards.activeList].fields[n - 1].text" type="text" />
          </div>
        </div>
        <div class="col">
          <div class="mt-2" v-for="n in 24" :key="n">
            <div v-if="n > 12">
              {{ n }} <input v-model="rewards.lists[rewards.activeList].fields[n - 1].text" type="text" />
            </div>
          </div>
        </div>
      </div>
    </center>
  </div>
  <toast ref="saveToast"
    title="Settings saved successfully"
    text="Your settings have been successfully saved and your overlay has been updated accordingly."
  />
  <button @click="save" class="btn btn-success btnSave">Save</button>
  <div class="selectLists">
    <label for="rewards">Choose active list: </label>

    <select v-if="rewards" v-model="rewards.activeList" name="rewards" class="mx-2">
      <option disabled value="">Please select one</option>
      <option v-for="(list, index) in rewards.lists" :key="index" :value="index">
        {{ list.name }}
      </option>
    </select>
    <button @click="removeList" class="btn btn-danger mx-2">Remove list</button>
    <br />
    <input v-model="newListName" class="mt-3" type="text" placeholder="New list name" />
    <button @click="addList" class="btn btn-success mx-3">Add list</button>
  </div>
</template>

<script>
import firebase from "firebase";
import toast from "../components/toast.vue";

export default {
  data() {
    return {
      rewards: null,
      newListName: "",
    };
  },
  mounted() {
      if (firebase.auth().currentUser.uid == "gWTvVBEseqSCzgIP7yHRsdgHWjB2") {
        alert("Not Available in demo account.");
        this.$router.push("/");
      }
      firebase
        .firestore()
        .collection(firebase.auth().currentUser.uid)
        .doc("rewards")
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.rewards = doc.data();
          }
        });
  },
  methods: {
    getNumber(number) {
      if (number < 10) {
        return "0" + number;
      } else {
        return number;
      }
    },
    save() {
      if (this.rewards) {
        firebase
          .firestore()
          .collection(firebase.auth().currentUser.uid)
          .doc("rewards")
          .update(this.rewards)
          .then(() => {
            this.$refs.saveToast.showToast();
          });
      }
    },
    addList() {
      this.rewards.lists.push({
        name: this.newListName,
        fields: [
          {
            text: "1€",
            gewichtung: 1,
            active: true,
          },
          {
            text: "3€",
            gewichtung: 2,
            active: true,
          },
          {
            text: "5€",
            gewichtung: 3,
            active: true,
          },
          {
            text: "7€",
            gewichtung: 4,
            active: true,
          },
          {
            text: "10€",
            gewichtung: 5,
            active: true,
          },
          {
            text: "25€",
            gewichtung: 6,
            active: true,
          },
          {
            text: "50€",
            gewichtung: 7,
            active: true,
          },
          {
            text: "70€",
            gewichtung: 8,
            active: true,
          },
          {
            text: "100€",
            gewichtung: 9,
            active: true,
          },
          {
            text: "250€",
            gewichtung: 10,
            active: true,
          },
          {
            text: "500€",
            gewichtung: 11,
            active: true,
          },
          {
            text: "1.000€",
            gewichtung: 12,
            active: true,
          },
          {
            text: "2.500€",
            gewichtung: 13,
            active: true,
          },
          {
            text: "5.000€",
            gewichtung: 14,
            active: true,
          },
          {
            text: "10.000€",
            gewichtung: 15,
            active: true,
          },
          {
            text: "20.000€",
            gewichtung: 16,
            active: true,
          },
          {
            text: "30.000€",
            gewichtung: 17,
            active: true,
          },
          {
            text: "50.000€",
            gewichtung: 18,
            active: true,
          },
          {
            text: "70.000€",
            gewichtung: 19,
            active: true,
          },
          {
            text: "100.000€",
            gewichtung: 20,
            active: true,
          },
          {
            text: "250.000€",
            gewichtung: 21,
            active: true,
          },
          {
            text: "500.000€",
            gewichtung: 22,
            active: true,
          },
          {
            text: "750.000€",
            gewichtung: 23,
            active: true,
          },
          {
            text: "1.000.000€",
            gewichtung: 24,
            active: true,
          },
        ],
      });
      this.newListName = ""
    },
    removeList() {
      if(this.rewards.lists.length > 1) {
        this.rewards.lists.splice(this.rewards.activeList, 1)
        this.rewards.activeList = 0
      } else {
        alert("You must have at least 1 list")
      }
    },
  },
  components: {
    toast,
  },
};
</script>

<style scoped>
.selectLists {
  position: absolute;
  left: 6rem;
  top: 5rem;
}
</style>
